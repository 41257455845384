// extracted by mini-css-extract-plugin
export var campuses = "contact-module--campuses--wBUOG";
export var campusesAll = "contact-module--campuses-all--7T5Y-";
export var contact = "contact-module--contact--HoUKq";
export var description = "contact-module--description--jLFdZ";
export var heroLocationBlock = "contact-module--hero-location-block--Vl04r";
export var heroTitle = "contact-module--hero-title--gEVSN";
export var infoBlock = "contact-module--info-block--J+41P";
export var learnMoreLink = "contact-module--learn-more-link--XCVNS";
export var locationBlock = "contact-module--location-block--IU0DJ";
export var locationInfo = "contact-module--location-info--60bER";
export var locations = "contact-module--locations--Sqgn4";
export var social = "contact-module--social--weBMl";
export var title = "contact-module--title--2OHzi";
export var titleBlock = "contact-module--title-block--q3tZx";